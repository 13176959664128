.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1920px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1920px) { /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -960px;   /* 50% */
  }
}

img.bandNameImage {
  width: 337.6px;

  margin-top: 100px;
  margin-bottom: 75px;
}

@media screen and (max-width: 500px) {
  img.bandNameImage {
    width: 272px;
  }
}

.recentReleaseWrapper {
  margin-bottom: 80px;
}

.recentReleaseImage {
  width: 384px;
}


@media screen and (max-width: 500px) {
  .recentReleaseImage {
    width: 280px;
  }
} 

.iconsWrapper {
  display: flex;
  justify-content: center;
}

.linked-icons {
  width: 384px;
  display: flex;
}

@media screen and (max-width: 500px) {
  .linked-icons {
    width: 75%;
  }
}

.mediaAnchor {
  flex: auto;
}

.mediaImage {
  width: 43px;
}

